import { App } from "vue";
import * as Sentry from "@sentry/vue";

function find_release() {
  if (document.cookie.indexOf("release=") < 0) {
    console.log("Sentry disabled");
    return;
  }

  const cookies = document.cookie.split("; ");
  var release = "";
  for (var i = 0; i < cookies.length; i++) {
    if (cookies[i].startsWith("release=")) {
      release = cookies[i].substring(8);
    }
  }
  if (release == "") {
    console.log("Failed to find a release");
    return;
  }
  return release;
}

function create_sentry(app: App, release: string) {
  var data = {
    dsn: "https://c26be70c674049dfbdf71b1bb5795e68@o4505189111758848.ingest.sentry.io/4505189114773504",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    app: app,
    release: release,
    //integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  };
  return Sentry.init(data);
}

const attach_sentry = (function () {
  var sentry: any = null;
  return function (app: App) {
    if (sentry != null) {
      // @ts-ignore
      app.mixin(Sentry.createTracingMixins({ trackComponents: true }));
      // @ts-ignore
      Sentry.attachErrorHandler(app);
      return;
    }
    const release = find_release();
    if (release == undefined || release == null) {
      return null;
    }
    sentry = create_sentry(app, release);
  };
})();

export function initialize_sentry(app: App) {
  attach_sentry(app);
}

export const exportedForTesting = {
  find_release,
};
